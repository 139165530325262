import {
  Alert,
  Badge,
  Button,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Select,
  Table,
  Tooltip,
  Typography,
  Popover,
} from "antd";

import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { v4 as uuid } from "uuid";

const CONFLICT_TYPE = {
  MISSING: "MISSING",
  MISMATCH: "MISMATCH",
};

const getColorConflict = (type) => {
  switch (type) {
    case CONFLICT_TYPE.MISSING:
      return "danger";
    case CONFLICT_TYPE.MISMATCH:
      return "warning";
    default:
      return null;
  }
};
@inject("rootStore")
@observer
class TableInputTopology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseData: [],
    };
  }
  getDirectionColumns = () => {
    const {
      t,
      rootStore: {
        intersectionConfigStore: {
          setFieldTopology,
          pushFieldTopology,
          removeFieldTopology,
          metaData,
        },
      },
      form: { getFieldDecorator },
    } = this.props;

    return [
      {
        title: t("intersection_config.Direction"),
        dataIndex: "Direction",
        key: "Direction",
        width: "15%",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.Direction")}>
            <Form.Item>
              {getFieldDecorator(`${index}.Direction`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([index, "Direction"], e);
                  return e;
                },
              })(
                <Select placeholder={t("intersection_config.Direction")}>
                  {metaData.directions?.map((e) => (
                    <Select.Option key={e.id} value={e.name}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {this.props.idError.includes(record.id) && (
              <Typography.Text type="danger" className="absolute">
                {t("intersection_config.invalid")}
              </Typography.Text>
            )}
          </Tooltip>
        ),
      },
      {
        width: "10%",
        title: t("intersection_config.ApproachSpeed"),
        dataIndex: "ApproachSpeed",
        key: "ApproachSpeed",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.ApproachSpeed")}>
            <Form.Item className="w-full">
              {getFieldDecorator(`${index}.ApproachSpeed`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([index, "ApproachSpeed"], e);
                  return e;
                },
              })(
                <InputNumber
                  min={0}
                  placeholder={t("intersection_config.ApproachSpeed")}
                  className="table-input w-full"
                />
              )}
            </Form.Item>
          </Tooltip>
        ),
      },
      {
        width: "15%",
        title: t("intersection_config.UpstreamEntityID"),
        dataIndex: "UpstreamEntityID",
        key: "UpstreamEntityID",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.UpstreamEntityID")}>
            <Form.Item>
              {getFieldDecorator(`${index}.UpstreamEntityID`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([index, "UpstreamEntityID"], e.target.value);
                  return e.target.value;
                },
              })(
                <Input
                  placeholder={t("intersection_config.UpstreamEntityID")}
                  className="table-input"
                />
              )}
            </Form.Item>
          </Tooltip>
        ),
      },
      {
        width: "7.5%",
        title: t("intersection_config.UpstreamDistance"),
        dataIndex: "UpstreamDistance",
        key: "UpstreamDistance",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.UpstreamDistance")}>
            <Form.Item>
              {getFieldDecorator(`${index}.UpstreamDistance`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([index, "UpstreamDistance"], e);
                  return e;
                },
              })(
                <InputNumber
                  min={0}
                  placeholder={t("intersection_config.UpstreamDistance")}
                  className="table-input w-full"
                />
              )}
            </Form.Item>
          </Tooltip>
        ),
      },

      {
        width: "15%",
        title: t("intersection_config.DownstreamEntityID"),
        dataIndex: "DownstreamEntityID",
        key: "DownstreamEntityID",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.DownstreamEntityID")}>
            <Form.Item>
              {getFieldDecorator(`${index}.DownstreamEntityID`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology(
                    [index, "DownstreamEntityID"],
                    e.target.value
                  );
                  return e.target.value;
                },
              })(
                <Input
                  placeholder={t("intersection_config.DownstreamEntityID")}
                  className="table-input"
                />
              )}
            </Form.Item>
          </Tooltip>
        ),
      },
      {
        width: "7.5%",
        title: t("intersection_config.DownstreamDistance"),
        dataIndex: "DownstreamDistance",
        key: "DownstreamDistance",
        render: (text, record, index) => (
          <Tooltip title={t("intersection_config.DownstreamDistance")}>
            <Form.Item>
              {getFieldDecorator(`${index}.DownstreamDistance`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([index, "DownstreamDistance"], e);
                  return e;
                },
              })(
                <InputNumber
                  min={0}
                  placeholder={t("intersection_config.DownstreamDistance")}
                  className="table-input w-full"
                />
              )}
            </Form.Item>
          </Tooltip>
        ),
      },

      {
        width: "10%",
        key: "AddNewLane",
        render: (text, record, index) => (
          <a
            onClick={() => {
              pushFieldTopology([index, "Phases"], {
                PhaseNumber: null,
                Type: null,
                Lanes: [],
                id: uuid(),
              });
              this.onExpand(true, record);
            }}>
            <Icon type="plus" /> {t("intersection_config.add_phase")}
          </a>
        ),
      },
      {
        width: "10%",
        key: "DeleteLane",
        render: (text, record, index) => (
          <a
            className="color-danger"
            onClick={() => {
              this.onExpand(false, record);
              removeFieldTopology([], index);
            }}>
            <Icon type="close" /> {t("delete")}
          </a>
        ),
      },
    ];
  };
  getDetectorColumns = (fieldTree) => {
    const {
      t,
      rootStore: {
        intersectionConfigStore: {
          removeFieldTopology,
          setFieldTopology,
          metaData,
        },
      },
      form: { getFieldDecorator },
    } = this.props;
    return [
      {
        width: "20%",
        title: t("intersection_config.DetectorNumber"),
        dataIndex: "DetectorNumber",
        key: "DetectorNumber",
        render: (text, record, index) => (
          <>
            <Form.Item
              label={t("intersection_config.DetectorNumber")}
              labelCol={{ span: 9 }}
              style={{ paddingLeft: "8.5%" }}
              labelAlign="left"
              wrapperCol={{ span: 15 }}>
              {getFieldDecorator(
                `${fieldTree.join(".")}${index}.DetectorNumber`,
                {
                  initialValue: text,
                  getValueFromEvent: (e) => {
                    setFieldTopology(
                      [...fieldTree, index, "DetectorNumber"],
                      e
                    );
                    return e;
                  },
                }
              )(
                <InputNumber
                  min={1}
                  max={64}
                  placeholder={t("intersection_config.DetectorNumber")}
                  className="table-input w-full"
                />
              )}
            </Form.Item>
            {this.props.idError.includes(record.id) && (
              <Typography.Text
                type="danger"
                className="absolute"
                style={{ paddingLeft: "7%" }}>
                {t("intersection_config.invalid")}
              </Typography.Text>
            )}
          </>
        ),
      },
      {
        width: "12.5%",
        title: "Type",
        dataIndex: "Type",
        key: "Type",
        render: (text, record, index) => (
          <Form.Item>
            {getFieldDecorator(`${fieldTree.join(".")}${index}.Type`, {
              initialValue: text,
              getValueFromEvent: (e) => {
                setFieldTopology([...fieldTree, index, "Type"], e);
                return e;
              },
            })(
              <Select placeholder={t("intersection_config.Type")}>
                {metaData.detector_type?.map((e) => (
                  <Select.Option key={e.value} value={e.value}>
                    {e.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ),
      },
      {
        width: "12.5%",
        title: "Distance",
        dataIndex: "Distance",
        key: "Distance",
        render: (text, record, index) => (
          <Form.Item
            label="Distance"
            labelCol={{ span: 10 }}
            labelAlign="left"
            wrapperCol={{ span: 14 }}>
            {getFieldDecorator(`${fieldTree.join(".")}${index}.Distance`, {
              initialValue: text,
              getValueFromEvent: (e) => {
                setFieldTopology([...fieldTree, index, "Distance"], e);
                return e;
              },
            })(
              <InputNumber
                min={0}
                placeholder={t("intersection_config.Distance")}
                className="table-input w-full"
              />
            )}
          </Form.Item>
        ),
      },
      {
        width: "57.5%",
        key: "Delete",
        render: (text, record, index) => (
          <a
            className="color-danger"
            onClick={() => removeFieldTopology(fieldTree, index)}>
            <Icon type="close" /> {t("delete")}
          </a>
        ),
      },
    ];
  };
  getThroughColumns = (fieldTree) => {
    const {
      form: { getFieldDecorator },
      t,
      rootStore: {
        intersectionConfigStore: {
          pushFieldTopology,
          removeFieldTopology,
          setFieldTopology,
          metaData,
        },
      },
    } = this.props;
    return [
      {
        width: "19.0592%",
        title: "Movement",
        dataIndex: "Movement",
        key: "Movement",
        render: (text, record, index) => (
          <>
            <Form.Item
              style={{ paddingLeft: "8%" }}
              label="Movement"
              labelAlign="left"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}>
              {getFieldDecorator(`${fieldTree.join(".")}${index}.Movement`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([...fieldTree, index, "Movement"], e);
                  return e;
                },
              })(
                <Select placeholder={t("intersection_config.Movement")}>
                  {metaData.movements?.map((e) => (
                    <Select.Option key={e.id} value={e.name}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {this.props.idError.includes(record.id) && (
              <Typography.Text
                type="danger"
                style={{ paddingLeft: "7%" }}
                className="absolute">
                {t("intersection_config.invalid")}
              </Typography.Text>
            )}
          </>
        ),
      },
      {
        width: "11.6666%",
        key: "AddAssignDetector",
        render: (text, record, index) => (
          <a
            onClick={() => {
              pushFieldTopology([...fieldTree, index, "Detectors"], {
                DetectorNumber: null,
                Type: null,
                Distance: null,
                id: uuid(),
              });
              this.onExpand(true, record);
            }}>
            <Icon type="plus" /> {t("intersection_config.assign_detector")}
          </a>
        ),
      },
      {
        key: "DeleteDetector",
        render: (text, record, index) => (
          <a
            className="color-danger"
            onClick={() => {
              this.onExpand(false, record);
              removeFieldTopology(fieldTree, index);
            }}>
            <Icon type="close" /> {t("delete")}
          </a>
        ),
      },
    ];
  };
  getLanesColumns = (fieldTree) => {
    const {
      t,
      form: { getFieldDecorator },
      rootStore: {
        intersectionConfigStore: {
          pushFieldTopology,
          removeFieldTopology,
          setFieldTopology,
          metaData,
        },
      },
    } = this.props;
    return [
      {
        width: "11.75%",
        title: "PhaseNumber",
        dataIndex: "PhaseNumber",
        key: "PhaseNumber",
        render: (text, record, index) => (
          <>
            <Form.Item
              label="Phase"
              labelAlign="left"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}>
              {getFieldDecorator(`${fieldTree.join(".")}${index}.PhaseNumber`, {
                initialValue: text,
                getValueFromEvent: (e) => {
                  setFieldTopology([...fieldTree, index, "PhaseNumber"], e);
                  return e;
                },
              })(
                <Select placeholder={t("intersection_config.PhaseNumber")}>
                  {metaData.phases?.map((e) => (
                    <Select.Option key={e} value={e}>
                      {e}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {this.props.idError.includes(record.id) && (
              <Typography.Text type="danger" className="absolute">
                {t("intersection_config.invalid")}
              </Typography.Text>
            )}
          </>
        ),
      },
      {
        width: "11.25%",
        key: "AddNewMovement",
        render: (text, record, index) => (
          <a
            onClick={() => {
              pushFieldTopology([...fieldTree, index, "Lanes"], {
                Movement: null,
                Detectors: [],
                id: uuid(),
              });
              this.onExpand(true, record);
            }}>
            <Icon type="plus" /> {t("intersection_config.add_lanes")}
          </a>
        ),
      },
      {
        key: "DeleteMovement",
        render: (text, record, index) => {
          return (
            <a
              className="color-danger"
              onClick={() => {
                this.onExpand(false, record);
                removeFieldTopology(fieldTree, index);
              }}>
              <Icon type="close" /> {t("delete")}
            </a>
          );
        },
      },
    ];
  };
  onExpand = (expanded, record) => {
    if (expanded)
      this.setState({
        collapseData: this.state.collapseData.filter((e) => e !== record.id),
      });
    else
      this.setState({
        collapseData: [...this.state.collapseData, record.id],
      });
  };
  render() {
    const {
      t,
      rootStore: {
        intersectionConfigStore: {
          topologyConfig: { Approaches, conflict },
          currentConfig,
          pushFieldTopology,
        },
      },
      actionStatus,
    } = this.props;

    return (
      <>
        <div className="my-8 px-8 w-full flex items-center wrap-topology justify-space-beetwen ">
          <div className="flex mr-8">
            <div className="mr-8">
              <Button
                type="primary"
                icon="plus"
                onClick={() => {
                  pushFieldTopology([], {
                    Direction: null,
                    ApproachSpeed: 0,
                    UpstreamEntityID: "",
                    DownstreamEntityID: "",
                    UpstreamDistance: 0,
                    Phases: [],
                    id: uuid(),
                    DownstreamDistance: 0,
                  });
                }}>
                {t("intersection_config.add_direction")}
              </Button>
            </div>
            {conflict ? (
              <div>
                <Popover
                  placement="bottom"
                  trigger="hover"
                  content={
                    <div>
                      <div className="mb-8">
                        <span className="mr-8">
                          {t("intersection_config.det_phase_mapping")}
                        </span>
                        <Badge
                          className="border-square mr-8"
                          status="error"
                          text="Missing"
                        />
                        <Badge
                          className="border-square"
                          status="warning"
                          text="Missmatch"
                        />
                      </div>
                      <div className="flex">
                        <div className="mr-16">
                          <p>{t("intersection_config.tactic_config")}</p>
                          {conflict?.tactic_pairs.map((bug, id) => (
                            <div key={id}>
                              <Typography.Text
                                type={getColorConflict(
                                  bug.conflict_type
                                )}>{`${t("intersection_config.detector")} ${
                                bug.detector
                              } - ${t("intersection_config.Phase")} ${
                                bug.phase
                              }`}</Typography.Text>
                            </div>
                          ))}
                        </div>
                        <div>
                          <p>{t("intersection_config.int_topology")}</p>
                          {conflict?.topology_pairs.map((bug, id) => (
                            <div key={id}>
                              <Typography.Text
                                key={id}
                                type={getColorConflict(
                                  bug.conflict_type
                                )}>{`${t("intersection_config.detector")} ${
                                bug.detector
                              } - ${t("intersection_config.Phase")} ${
                                bug.phase
                              }`}</Typography.Text>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  }>
                  <div>
                    <Alert
                      message={t("intersection_config.topology_mismatch", {
                        config_id: currentConfig.config_id,
                      })}
                      className="topology-alert"
                      type="warning"
                      showIcon
                    />
                  </div>
                </Popover>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div>
            {actionStatus ? (
              <Alert className="topology-alert" showIcon {...actionStatus} />
            ) : (
              <></>
            )}
          </div>
        </div>

        {Approaches?.length ? (
          <Table
            showHeader={false}
            pagination={false}
            className="topology-table"
            columns={this.getDirectionColumns()}
            rowKey={"id"}
            dataSource={Approaches}
            expandedRowKeys={Approaches.map((e) =>
              this.state.collapseData.includes(e.id) ? null : e.id
            )}
            onExpand={this.onExpand}
            expandedRowRender={(record, indexDirection) => {
              return record.Phases?.length ? (
                <Table
                  expandedRowKeys={record.Phases.map((e) =>
                    this.state.collapseData.includes(e.id) ? null : e.id
                  )}
                  onExpand={this.onExpand}
                  rowKey="id"
                  columns={this.getLanesColumns([indexDirection, "Phases"])}
                  dataSource={record.Phases}
                  showHeader={false}
                  pagination={false}
                  expandedRowRender={(record, indexLane) => {
                    return record.Lanes?.length ? (
                      <Table
                        columns={this.getThroughColumns([
                          indexDirection,
                          "Phases",
                          indexLane,
                          "Lanes",
                        ])}
                        expandedRowKeys={record.Lanes.map((e) =>
                          this.state.collapseData.includes(e.id) ? null : e.id
                        )}
                        onExpand={this.onExpand}
                        rowKey="id"
                        showHeader={false}
                        dataSource={record.Lanes}
                        pagination={false}
                        expandedRowRender={(record, indexMovement) => {
                          return record.Detectors?.length ? (
                            <Table
                              rowKey="id"
                              columns={this.getDetectorColumns([
                                indexDirection,
                                "Phases",
                                indexLane,
                                "Lanes",
                                indexMovement,
                                "Detectors",
                              ])}
                              showHeader={false}
                              pagination={false}
                              dataSource={record.Detectors}
                            />
                          ) : (
                            <Empty
                              description={`${t(
                                "intersection_config.Detectors"
                              )} ${t("intersection_config.is_empty")}`}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          );
                        }}
                      />
                    ) : (
                      <Empty
                        description={`${t("intersection_config.Movement")} ${t(
                          "intersection_config.is_empty"
                        )}`}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    );
                  }}
                />
              ) : (
                <Empty
                  description={`${t("intersection_config.Phase")}s ${t(
                    "intersection_config.is_empty"
                  )}`}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              );
            }}
          />
        ) : (
          <Empty
            description={`${t("intersection_config.Direction")}s ${t(
              "intersection_config.is_empty"
            )}`}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </>
    );
  }
}

const WrapForm = Form.create({})(TableInputTopology);
export default WrapForm;
